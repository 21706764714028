export default class Payment {
  constructor(data) {
    this.methods = null;
    this.key = null;
    this.expiration_time = null;
    this.currency = null;
    this.max_value = 0;
    this.min_value = 0;
    this.capture = {
      descriptive: "Donativo Make A Wish",
    };
    // Initialize data
    if (data) {
      Object.assign(this, {
        methods: ["cc", "mb", "mbw"],
        key: data.key,
        expiration_time: data.expiration_time,
        currency: "EUR",
        max_value: data.max_value,
        min_value: data.min_value,
        capture: {
          descriptive: "Donativo Make A Wish",
        },
      });
    }
  }
}
