import "./assets/scss/main.scss";

import "./plugins/axios";
import Vue from "vue";
import Vuelidate from "vuelidate";
import VueCurrencyInput from "vue-currency-input";
import VBodyScrollLock from "v-body-scroll-lock";
import router from "./router";
import App from "./App.vue";
import store from "./store";
import MazPhoneNumberInput from "maz-ui";
import "maz-ui/lib/css/index.css";

Vue.use(Vuelidate);
Vue.use(VueCurrencyInput);
Vue.use(VBodyScrollLock);
Vue.use(MazPhoneNumberInput);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
