import { SET_CHECKOUT, SET_CHECKOUT_RESPONSE } from "./mutation-types";

export default {
  [SET_CHECKOUT](state, data) {
    state.checkout = data;
  },
  [SET_CHECKOUT_RESPONSE](state, data) {
    state.checkoutResponse = data;
  }
};
