export default class Customer {
  constructor(data) {
    this.name = null;
    this.email = null;
    this.phone = null;
    this.phone_indicative = null;
    this.fiscal_number = null;
    // Initialize data
    if (data) {
      Object.assign(this, {
        name: data.name,
        email: data.email,
        phone: data.phone,
        language: data.phone_indicative,
        fiscal_number: data.fiscal_number
      });
    }
  }
}
