import Payment from "./checkout-payment";
import Customer from "./checkout-customer";
import Order from "./checkout-order";
import Config from "./checkout-config";

export default class Checkout {
  constructor(data) {
    this.type = null;
    this.payment = new Payment();
    this.customer = new Customer();
    this.order = new Order();
    this.config = new Config();
    // Initialize data
    if (data) {
      Object.assign(this, {
        type: ["single"],
        payment: Checkout.getPayment(data.payment),
        customer: Checkout.getCustomer(data.customer),
        order: Checkout.getOrder(data.order),
        config: Checkout.getConfig(data.config)
      });
    }
  }

  static getPayment(data) {
    return data.map(payment => new Payment(payment));
  }
  static getCustomer(data) {
    return data.map(customer => new Customer(customer));
  }
  static getOrder(data) {
    return data.map(order => new Order(order));
  }
  static getConfig(data) {
    return data.map(config => new Config(config));
  }
}
