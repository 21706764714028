import * as types from "./mutation-types";
import CheckoutService from "@/services/checkout";
import { getCheckoutResponse } from "../../../services/checkout/responses/responses";

export async function postCheckout({ commit }, checkout) {
  const response = await CheckoutService.postCheckout(checkout);
  const data = getCheckoutResponse(response);

  commit(types.SET_CHECKOUT, checkout);
  commit(types.SET_CHECKOUT_RESPONSE, data);
}

export default {
  postCheckout
};
