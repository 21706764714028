import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  base: __dirname,
  mode: "history",
  routes: [
    {
      path: "/easypay",
      name: "easypay",
      component: resolve => {
        require.ensure(["./pages/easypay/easypay.vue"], () => {
          resolve(require("./pages/easypay/easypay.vue"));
        });
      }
    },
    {
      path: "/error",
      component: resolve => {
        require.ensure(["./pages/error.vue"], () => {
          resolve(require("./pages/error.vue"));
        });
      }
    },
    {
      path: "/:id",
      name: "home",
      component: resolve => {
        require.ensure(["./pages/home.vue"], () => {
          resolve(require("./pages/home.vue"));
        });
      }
    },
    {
      path: "/",
      component: resolve => {
        require.ensure(["./pages/error.vue"], () => {
          resolve(require("./pages/error.vue"));
        });
      }
    }
  ]
});

export default router;
