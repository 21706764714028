export const getMainConfig = (state) => state.mainConfig;
export const getQuantity = (state) => state.mainConfig.partner.stars;
export const getMaxQuantity = (state) => state.mainConfig.partner.maxStars;
export const getPartnerLogo = (state) => state.mainConfig.partner.image;
export const getPartnerBackgroundImage = (state) =>
  state.mainConfig.partner.backgroundImage;
export const getPartnerBackgroundImageMobile = (state) =>
  state.mainConfig.partner.backgroundImageMobile;
export const getAuxValue = (state) => state.auxiliarValue;

export default {
  getMainConfig,
  getQuantity,
  getMaxQuantity,
  getPartnerLogo,
  getPartnerBackgroundImage,
  getPartnerBackgroundImageMobile,
  getAuxValue
};
