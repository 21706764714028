export default class Response {
  constructor(data) {
    this.config = {};
    this.id = null;
    this.session = null;
    // Initialize data
    if (data) {
      Object.assign(this, {
        config: data.config,
        id: data.id,
        session: data.session
      });
    }
  }
}
