export default class Order {
  constructor(data) {
    this.key = null;
    this.value = null;
    this.items = [];
    // Initialize data
    if (data) {
      Object.assign(this, {
        key: data.key,
        value: data.value,
        items: data.items
      });
    }
  }
}
