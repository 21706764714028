export default class Config {
  constructor(data) {
    this.language = null;
    this.logo_url = null;
    // Initialize data
    if (data) {
      Object.assign(this, {
        language: data.language,
        logo_url: data.logo_url
      });
    }
  }
}
