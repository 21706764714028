import { SET_MAIN_CONFIG, SET_AUX_VALUE, SET_QUANTITY } from "./mutation-types";

export default {
  [SET_MAIN_CONFIG](state, data) {
    state.mainConfig = data;
  },

  [SET_AUX_VALUE](state, data) {
    state.auxiliarValue = data;
  },

  [SET_QUANTITY](state, data) {
    state.mainConfig.partner.stars = data;
  }
};
