import * as types from "./mutation-types";
import PartnerService from "@/services/partner";
import { getMainConfigResponse } from "../../../services/partner/responses/responses";

export async function setPartner({ commit, state }, [partner, auxValue]) {
  try {
    const response = await PartnerService.getPartner(partner);
    const data = getMainConfigResponse(response);

    if (data) {
      commit(types.SET_MAIN_CONFIG, data);
      // validates Auxiliar Value
      if (auxValue) {
        commit(types.SET_QUANTITY, state.mainConfig.partner.stars + auxValue);
      }
    }

    commit(types.SET_AUX_VALUE, 0);
  } catch (error) {
    console.log(error);
  }
}

export function setAuxValue({ commit }, value) {
  commit(types.SET_AUX_VALUE, value);
}

export default {
  setPartner,
  setAuxValue,
};
