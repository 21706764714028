import Vue from "vue";

const route = "/payment/SendCheckoutData";

const success = (response) => {
  const { data } = response;

  return data;
};

const failure = (error) => {
  const { response } = error;

  return response;
};

export default (checkoutData) => {
  console.log(checkoutData);
  return Vue.$http
    .post(`${route}`, checkoutData)
    .then((response) => success(response))
    .catch((error) => failure(error));
};
