import Vue from "vue";
import Vuex from "vuex";
import partner from "./modules/partner";
import checkout from "./modules/checkout";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { partner, checkout }
});
