export default class Partner {
  constructor(data) {
    this.id = null;
    this.image = null;
    this.name = null;
    this.partnerUrl = "";
    this.maxStars = 0;
    this.stars = 0;
    this.backgroundImage = "";
    this.backgroundImageMobile = "";

    // Initialize data
    if (data) {
      Object.assign(this, {
        id: data.id,
        image: data.image,
        name: data.name,
        partnerUrl: data.partnerUrl,
        maxStars: data.maxStars,
        stars: data.stars,
        backgroundImage: data.backgroundImage,
        backgroundImageMobile: data.backgroundImageMobile,
      });
    }
  }
}
