import Vue from "vue";

const route = "/partner/config";

const success = response => {
  const { data } = response;

  return data;
};

const failure = error => {
  const { response } = error;

  return response;
};

export default partner => {
  return Vue.$http
    .get(`${route}${partner}`)
    .then(response => success(response))
    .catch(error => failure(error));
};
