import Partner from "./main-config-partner";

export default class MainConfig {
  constructor(data) {
    this.js = null;
    this.partner = new Partner();
    // Initialize data
    if (data) {
      Object.assign(this, {
        js: data.js,
        partner: new Partner(data.partner)
      });
    }
  }
}
